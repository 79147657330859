import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import categoryProductApi from 'api/categoryProduct'
import productApi from 'api/product'
import variantApi from 'api/variant'


export const productSlice = createSlice({
    name: 'product',
    initialState: {
        listAllProduct: [],
        listProductByWarehouse: [],
        listCategory: [],
        listVariant: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCategory.fulfilled, (state, action) => {
                state.listCategory = action.payload
            })
            .addCase(getListVariant.fulfilled, (state, action) => {
                state.listVariant = action.payload
            })
            .addCase(getListProductByWarehouse.fulfilled, (state, action) => {
                state.listProductByWarehouse = action.payload
            })
            .addCase(getListAllProduct.fulfilled, (state, action) => {
                state.listAllProduct = action.payload
            })

    },
})

export const getListProductByWarehouse = createAsyncThunk(
    'product/getListProductByWarehouse',
    async (payload, { dispatch }) => {
        const res = await productApi.getByWarehouse(payload)
        return res
    }
)

export const getListAllProduct = createAsyncThunk(
    'product/getListAllProduct',
    async (payload, { dispatch }) => {
        const res = await productApi.getByWarehouse()
        return res
    }
)

export const getListCategory = createAsyncThunk(
    'product/getListCategory',
    async (payload, { dispatch }) => {
        const res = await categoryProductApi.getAll()
        return res
    }
)

export const getListVariant = createAsyncThunk(
    'product/getListVariant',
    async (payload, { dispatch }) => {
        const res = await variantApi.getAll()
        return res
    }
)

export const selectListAllProduct = (state) => state.product.listAllProduct
export const selectListProductByWarehouse = (state) => state.product.listProductByWarehouse
export const selectListCategory = (state) => state.product.listCategory
export const selectListVariant = (state) => state.product.listVariant

export default productSlice.reducer
