import { Button, Select, Space, Table, Tooltip } from "antd";
import orderApi from "api/order";
import classNames from "classnames/bind";
import { ROLE } from "constants/";
import { ORDER_STATUS } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { selectInfo } from "features/users/userSlice";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import style from '../../index.module.scss'
import renderHtml from "utils/renderHtml";

const cx = classNames.bind(style);
export default function OrderTable({
    orders,
    loading,
    pagination: { current_page: current, page_size: pageSize },
    total,
    onChangePage,
    openEdit,
    openDetail,
    onSearch,
    onUpdateSingle,
}) {
    const useInfo = useSelector(selectInfo)

    const [selectedRows, setSelectedRows] = useState([]);
    const [statusSeries, setStatusSeries] = useState('')

    const rowSelection = useInfo?.role?.id === ROLE.other ? '' : {
        selectedRowKeys: selectedRows.map(row => row.key),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
        preserveSelectedRowKeys: true,
    };

    const columns = [
        // {
        //     key: 'id',
        //     dataIndex: 'id',
        //     title: 'ID',
        //     width: '5%',
        //     // fixed: 'left',
        // },
        // {
        //     key: 'date',
        //     dataIndex: 'date',
        //     title: 'DATE',
        // },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'STATUS',
            width: '8%',
            render: (_, record) => (
                useInfo?.role.id == ROLE.other ? _ : <Select style={{ width: '100%' }} onChange={(value) => onUpdateSingle(record.id, value)} options={ORDER_STATUS} value={_} />
            )
        },
        {
            key: 'createdTime',
            dataIndex: 'createdTime',
            title: 'TIME',
            render: (_) => _ ? moment(_).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        {
            key: 'ads',
            dataIndex: 'ads',
            title: 'ADS',
        },
        {
            key: 'product',
            dataIndex: 'product',
            title: 'PRODUCT',
        },
        {
            key: 'fullName',
            dataIndex: 'fullName',
            title: 'FULLNAME',
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: 'PHONE NUMBER',
        },
        {
            key: 'orderDetails',
            dataIndex: 'orderDetails',
            title: 'ORDER DETAILS',
            width: '8%',
            ellipsis: {
                showTitle: false,
            },
            render: (_) => (
                <Tooltip placement="topLeft" title={_}>
                    {_}
                </Tooltip>
            ),
        },
        {
            key: 'quantity',
            dataIndex: 'quantity',
            title: 'QUANTITY',
        },
        {
            key: 'note',
            dataIndex: 'note',
            title: 'NOTE',
            width: '8%',
            ellipsis: {
                showTitle: false,
            },
            render: (_) => (
                <Tooltip placement="topLeft" title={_}>
                    <span dangerouslySetInnerHTML={{ __html: renderHtml(_?.replaceAll('\n', '<br />')) }} />
                </Tooltip>
            )
        },
        {
            key: 'address',
            dataIndex: 'address',
            title: 'ADDRESS',
            width: '8%',
            ellipsis: {
                showTitle: false,
            },
            render: (_) => (
                <Tooltip placement="topLeft" title={_}>
                    {_}
                </Tooltip>
            ),
        },
        {
            key: 'xa',
            dataIndex: 'xa',
            title: 'XA',
        },
        {
            key: 'huyen',
            dataIndex: 'huyen',
            title: 'HUYEN',
        },
        {
            key: 'tinh',
            dataIndex: 'tinh',
            title: 'TINH',
        },
        {
            key: 'shippingDate',
            dataIndex: 'shippingDate',
            title: 'SHIPPING DATE',
        },
        {
            key: 'staff',
            dataIndex: 'staff',
            title: 'STAFF',
        },
        {
            key: 'ip',
            dataIndex: 'ip',
            title: 'IP',
        },
        {
            key: 'isExport',
            dataIndex: 'isExport',
            title: 'EXPORT',
            render: (_) => +_ == 1 ? 'Đã xuất' : 'Chưa xuất'
        },
    ]

    const onUpdate = async () => {
        try {
            if (!statusSeries) {
                toast.error('Hãy chọn trạng thái    ')
                return;
            }
            await orderApi.update({
                requests: selectedRows.map(row => ({
                    id: row.id,
                    status: statusSeries
                }))
            })
            setStatusSeries('')
            onSearch()
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    return (
        <>
            <br />
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                <Select value={statusSeries} onChange={(val) => setStatusSeries(val)} allowClear style={{ width: '200px' }} disabled={selectedRows.length === 0}>
                    {
                        ORDER_STATUS.map(item => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))
                    }
                </Select>
                <Button onClick={onUpdate} type="primary" disabled={selectedRows.length === 0} loading={loading}>
                    Cập nhật hàng loạt
                </Button>
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    {selectedRows.length > 0 ? `Đã chọn ${selectedRows.length} đơn` : ''}
                </span>
            </Space>
            <Table
                className={cx('table-order')}
                rowSelection={rowSelection}
                loading={loading}
                columns={columns}
                dataSource={orders}
                scroll={{ x: '3000px' }}
                sticky={{
                    offsetHeader: 0,
                }}
                pagination={{ current, pageSize, total, onChange: onChangePage, showSizeChanger: true, showQuickJumper: <Button /> }} />
        </>

    )
}