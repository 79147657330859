import axiosService from "./axiosService"

const orderApi = {
    search(params) {
        const url = '/order/list'
        return axiosService.get(url, { params })
    },
    detail(id) {
        const url = `/order/detail/${id}`
        return axiosService.get(url)
    },
    create(url, body) {
        // const url = `/order/create123`
        return axiosService.post(url, body)
    },
    update(body) {
        const url = '/order/update'
        return axiosService.post(url, body)
    },
    export(params) {
        const url = '/order/export'
        return axiosService.get(url, { params, responseType: 'blob' })
    }
}

export default orderApi;