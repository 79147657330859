import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, Form, Space, Typography } from "antd";
import productApi from "api/product";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ModalCreateUpdate, ModalDetail, ProductFilter, ProductTable } from "./components";
import style from './index.module.scss'
import { ERROR_OCCUR } from "constants/messages";
import moment from "moment";
import { getUser } from "utils/";
import { ROLE } from "constants/";
import imageApi from "api/image";

const cx = classNames.bind(style)

export default function ProductPage() {
    const [formSearch] = Form.useForm()
    const [formProduct] = Form.useForm();

    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 20,
    })
    const [total, setTotal] = useState(0)
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [openCU, setOpenCU] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)

    const onOpenCreate = () => {
        setOpenCU(true)
    }

    const onOpenEdit = async (item) => {
        try {
            const res = await productApi.getDetail(item.id)
            setOpenCU(true)
            setSelected(res)
        } catch (error) {
            toast.error(error?.response?.data)
        }
    }

    const onOpenDetail = async (item) => {
        try {
            const res = await productApi.getDetail(item.id)
            setOpenDetail(true)
            setSelected(res)
        } catch (error) {
            toast.error(error?.response?.data)
        }
    }

    const closeModalDetail = () => {
        setOpenDetail(false)
        setSelected(null)
    }

    const closeModalCU = () => {
        setOpenCU(false)
        setSelected(null)
        formProduct.resetFields()
    }

    const onChangePage = (page) => {
        setPagination({
            ...pagination,
            current_page: page
        })
        onSearch(page)
    }

    const onSearch = async (page = pagination.current_page) => {
        try {
            const values = formSearch.getFieldsValue()
            setLoading(true)
            const res = await productApi.search({
                ...values,
                created_at: values.created_at && moment(values.created_at).format('YYYY-MM-DD'),
                end_date: values.end_date && moment(values.end_date).format('YYYY-MM-DD'),
                ...pagination,
                current_page: page - 1,
            })
            setProducts(res.responses)
            setTotal(res.totalRecord)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onExport = async () => {
        try {
            setLoading(true)
            const values = formSearch.getFieldsValue()
            const res = await productApi.export(values)
            const a = document.createElement('a')
            a.href = URL.createObjectURL(res)
            a.download = 'danh_sach_san_pham.xlsx'
            a.click()
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onSubmitProductCU = async (values) => {
        try {
            setLoading(true)
            if (!selected) {
                const body = {
                    ...values,
                    warehouse: JSON.stringify(values.warehouse),
                }
                const res = await imageApi.upload(values.img[0].originFileObj)
                body.img = res.replace(/\n/g, '')
                await productApi.createAndUpdate(body)
                toast.success('Tạo sản phẩm thành công')
            } else {
                const body = {
                    productId: selected.id,
                    ...values,
                    warehouse: JSON.stringify(values.warehouse),
                    img: selected.img
                }
                if (values.img[0].uid !== 'old') {
                    const res = await imageApi.upload(values.img[0].originFileObj)
                    body.img = res.replace(/\n/g, '')
                    imageApi.delete(selected.img)
                }
                await productApi.createAndUpdate(body)
                toast.success('Sửa sản phẩm thành công')
            }
            closeModalCU()
            onSearch()
        } catch (error) {
            toast.error(error?.response?.data || !selected ? 'Tạo sản phẩm thất bại' : 'Sửa sản phẩm thất bại')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        onSearch()
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Danh sách sản phẩm</Typography.Title>
                <Space>
                    {
                        (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
                        <Button size="large" icon={<PlusCircleFilled />} onClick={onOpenCreate}>
                            Thêm mới
                        </Button>
                    }
                    <Button size="large" type='primary' icon={<FileExcelFilled />} onClick={onExport} >
                        Xuất Excel
                    </Button>
                </Space>
            </div>
            <ProductFilter form={formSearch} onSearch={onChangePage} loading={loading} />
            <ProductTable
                openEdit={onOpenEdit}
                loading={loading}
                openDetail={onOpenDetail}
                pagination={pagination}
                total={total}
                onChangePage={onChangePage}
                products={products} />
            <ModalCreateUpdate
                onFinish={onSubmitProductCU}
                form={formProduct}
                selected={selected}
                show={openCU}
                onCancel={closeModalCU} />
            <ModalDetail
                show={openDetail}
                selected={selected}
                onCancel={closeModalDetail}
            />
        </>
    )
};
