import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { ROLE } from "constants/";
import { getUser } from "utils/";

export const renderColumns = (openEdit, openDelete) => [
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Tên nền tảng',
    },
    {
        key: 'action',
        dataIndex: 'action',
        title: 'Hành động',
        render: (_, record) => (
            (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
            <Space>
                <Button icon={<EditOutlined />} onClick={() => openEdit(record)} />
            </Space>
        )
    },
]