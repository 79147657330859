import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import teamApi from 'api/team'
import userApi from 'api/user'

export const userSlice = createSlice({
    name: 'user',
    info: {},
    initialState: {
        listTeam: [],
        listRole: []
    },
    reducers: {
        updateInfo: (state, action) => {
            console.log("🚀 ~ action:", action)
            state.info = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListTeam.fulfilled, (state, action) => {
                state.listTeam = action.payload
            })
            .addCase(getListRole.fulfilled, (state, action) => {
                state.listRole = action.payload
            })
    },
})

export const getListTeam = createAsyncThunk(
    'user/getListTeam',
    async (payload, { dispatch }) => {
        const res = await teamApi.getAll()
        return res
    }
)

export const getListRole = createAsyncThunk(
    'user/getListRole',
    async (payload, { dispatch }) => {
        const res = await userApi.getAllRole()
        return res
    }
)

export const { updateInfo } = userSlice.actions

export const selectListTeam = (state) => state.user.listTeam
export const selectListRole = (state) => state.user.listRole
export const selectInfo = (state) => state.user.info

export default userSlice.reducer
