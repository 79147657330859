import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import { selectListRole, selectListTeam } from "features/users/userSlice";
import { useSelector } from "react-redux";
import { getUser } from "utils/";

export default function UserFilter({ form, onSearch, loading }) {
    const listRole = useSelector(selectListRole)
    const listTeam = useSelector(selectListTeam)

    return (
        <Card>
            <Form form={form} onFinish={onSearch} layout='vertical' initialValues={{ status: 1 }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label='Tên nhân viên' name='name' >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Tên đăng nhập' name='username'>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Chức vụ' name='role_id'>
                            <Select allowClear style={{ width: '100%' }} options={listRole.filter(item => item.id > getUser().role.id).map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Team' name='team_id'>
                            <Select allowClear style={{ width: '100%' }} options={listTeam.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
