import axiosService from "./axiosService"

const countryApi = {
    getAll() {
        const url = '/country/list'
        return axiosService.get(url)
    },
    delete(id) {
        const url = `/country/${id}`
        return axiosService.delete(url)
    },
    createAndUpdate(body) {
        const url = '/country/update'
        return axiosService.post(url, body)
    }
}

export default countryApi;