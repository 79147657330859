import axios from "axios";
import { getToken, removeToken, removeUser } from "../utils";

// Set up default config for http requests here
const axiosService = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "content-type": "application/json",
    },
});

axiosService.interceptors.request.use(async (config) => {
    const accessToken = getToken();
    if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
})

axiosService.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response.data;
        }
        return response;
    },
    (error) => {
        switch (error.response.status) {
            case 401:
                removeToken()
                removeUser()
                window.location.href = "/login";

                break;
            default:
                return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);


export default axiosService;
