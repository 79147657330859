import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { ROLE } from "constants/";
import { numberWithCommas } from "utils/";
import { getUser } from "utils/";

export const renderColumns = (openEdit, openDelete) => [
    {
        key: 'categoryAds',
        dataIndex: 'categoryAds',
        title: 'Tên danh mục',
        render: (_) => _.name
    },
    {
        key: 'date',
        dataIndex: 'date',
        title: 'Ngày tạo qc',
    },
    {
        key: 'fee',
        dataIndex: 'fee',
        title: 'Chi phí',
        render: (_, record) => numberWithCommas(_) + ' ' + record.country.currency
    },
    {
        key: 'country',
        dataIndex: 'country',
        title: 'Thị trường',
        render: (_) => _.name
    },
    {
        key: 'action',
        dataIndex: 'action',
        title: 'Hành động',
        render: (_, record) => (
            (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
            <Space>
                <Button icon={<EditOutlined />} onClick={() => openEdit(record)} />
                <Button danger icon={<DeleteOutlined />} onClick={() => openDelete(record)} />
            </Space>
        )
    },
]