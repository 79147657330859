import { DeleteFilled } from "@ant-design/icons";
import { Modal } from "antd";
import style from './index.module.scss'
import classNames from "classnames/bind";

const cx = classNames.bind(style)
export default function ModalDelete({ title, description = 'Bạn có chắc muốn xóa?', show, onOk, onCancel, okText = 'Xoá', ...rest }) {
    return (
        <Modal
            title={title}
            open={show}
            onOk={onOk}
            okText={okText}
            cancelText='Hủy'
            okButtonProps={{
                danger: true
            }}
            onCancel={onCancel}
            {...rest}
        >
            <div className={cx('wrapper')}>
                <DeleteFilled className={cx('delete-ic')} />
                <strong>{description}</strong>
            </div>
        </Modal>

    )
};
