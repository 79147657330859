import { DeleteOutlined, EditOutlined, LockOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Image, Space } from "antd";
import { ROLE } from "constants/";
import { getUser } from "utils/";
import { ModalResetPassword } from "./components";

export const renderColumns = (onEdit, onDelete, onSearch) => [
    {
        key: 'img',
        dataIndex: 'img',
        title: 'Ảnh',
        render: (_) => <Avatar shape="square" size={60} src={process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_PATH + _} />
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Tên NV',
    },
    {
        key: 'username',
        dataIndex: 'username',
        title: 'Username',
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Trạng thái',
        width: '10%',
        render: (_) => _ == 1 ? <Badge status="success" text='Đang làm' /> : <Badge status="error" text='Nghỉ việc' />
    },
    {
        key: 'email',
        dataIndex: 'email',
        title: 'Email',
    },
    {
        key: 'phoneNumber',
        dataIndex: 'phoneNumber',
        title: 'SĐT',
    },
    {
        key: 'role',
        dataIndex: 'role',
        title: 'Chức vụ',
        render: (_) => _?.name
    },
    {
        key: 'warehouse',
        dataIndex: 'warehouse',
        title: 'Kho',
        render: (_) => _?.name
    },
    {
        key: 'team',
        dataIndex: 'team',
        title: 'Team',
        render: (_) => _?.name
    },
    {
        key: 'action',
        dataIndex: 'action',
        title: 'Hành động',
        render: (_, record) => (
            (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
            <Space>
                <Button icon={<UserSwitchOutlined />} onClick={() => onEdit(record)} />
                <ModalResetPassword record={record} onSearch={onSearch} />
                {/* <Button danger icon={<DeleteOutlined />} onClick={() => onDelete(record)} /> */}
            </Space>
        )
    }

]