import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/common.scss'
import { ToastifyContainer } from './components';
import { ConfigProvider, Spin } from 'antd';
import { Provider } from 'react-redux';
import store from 'app/store';
import { validateMessages } from './constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Spin />}>
    <ToastifyContainer />
    <Provider store={store}>
      <ConfigProvider form={{ validateMessages }}>
        <App />
      </ConfigProvider>
    </Provider>
  </Suspense>
);
