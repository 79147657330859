import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { ROLE } from "constants/";
import { selectListRole, selectListTeam } from "features/users/userSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUser } from "utils/";

export default function ModalCreateUpdate({ form, onFinish, selected, show, onCancel }) {
    const roleId = Form.useWatch('roleId', form)

    const listRole = useSelector(selectListRole)
    const listTeam = useSelector(selectListTeam)
    const listWarehouse = useSelector(selectListWarehouse)

    const checkPassword = (_, value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!value || (value.length >= 8 && regex.test(value))) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Mật khẩu chưa hợp lệ.'));
    }

    useEffect(() => {
        if (show && selected) {
            form.setFieldsValue({
                ...selected,
                roleId: selected.role.id,
                teamId: selected.team?.id,
                warehouseId: selected.warehouse?.id
            })
        }
    }, [selected, show])


    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title={!selected ? 'Thêm mới nhân viên' : 'Cập nhật nhân viên'}
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <Row gutter={16}>
                    {
                        !selected &&
                        <>
                            <Col span={12}>
                                <Form.Item label='Tên đăng nhập' name='username' rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </>
                    }
                    <Col span={12}>
                        <Form.Item label='Chức vụ' name='roleId' rules={[{ required: true }]}>
                            <Select allowClear style={{ width: '100%' }} options={listRole.filter(item => item.id > getUser().role.id).map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    {
                        (!roleId || roleId == ROLE.other) &&
                        <Col span={24}>
                            <Form.Item label='Team' name='teamId' rules={[{ required: true }]}>
                                <Select allowClear style={{ width: '100%' }} options={listTeam.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }
                    {
                        roleId == ROLE.accountant &&
                        <Col span={24}>
                            <Form.Item label='Kho' name='warehouseId' rules={[{ required: true }]}>
                                <Select allowClear style={{ width: '100%' }} options={listWarehouse.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }
                    {
                        !selected &&
                        <>
                            <Col span={12}>
                                <Form.Item
                                    label='Mật khẩu'
                                    name='password'
                                    rules={[
                                        { required: true },
                                        { validator: checkPassword, }
                                    ]}
                                    extra={<Space direction="vertical">
                                        <span>Mật khẩu ít nhất 8 ký tự</span>
                                        <span>Mật khẩu phải chứa ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt</span>
                                    </Space>}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='Xác nhận mật khẩu'
                                    name='confirmPassword'
                                    rules={[
                                        { required: true },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Mật khẩu không khớp'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </>
                    }
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type='primary' size='large'>
                                    {selected ? "Sửa" : 'Tạo'}
                                </Button>
                                <Button size='large' onClick={onCancel}>
                                    Huỷ
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        </Modal >
    )
}