import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, Form, Space, Typography } from "antd";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ComboTable, Filter, ModalCreateUpdate, ModalDetail } from "./components";
import style from './index.module.scss'
import { ERROR_OCCUR } from "constants/messages";
import moment from "moment";
import comboApi from "api/combo";
import { useDispatch } from "react-redux";
import { getListAllProduct } from "features/products/productSlice";
import { getUser } from "utils/";
import { ROLE } from "constants/";

const cx = classNames.bind(style)

export default function ComboPage() {
    const dispatch = useDispatch()

    const [formSearch] = Form.useForm()
    const [formCombo] = Form.useForm();

    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 20,
    })
    const [total, setTotal] = useState(0)
    const [combos, setCombos] = useState([])
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [openCU, setOpenCU] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)

    const onOpenCreate = () => {
        setOpenCU(true)
    }

    const onOpenEdit = async (item) => {
        setSelected(item)
        setOpenCU(true)
    }

    const closeModalCU = () => {
        setOpenCU(false)
        setSelected(null)
        formCombo.resetFields()
    }

    const onOpenDetail = async (item) => {
        setSelected(item)
        setOpenDetail(true)
    }

    const closeModalDetail = () => {
        setOpenDetail(false)
        setSelected(null)
    }

    const onChangePage = (page) => {
        setPagination({
            ...pagination,
            current_page: page
        })
        onSearch(page)
    }

    const onSearch = async (page = pagination.current_page) => {
        try {
            const values = formSearch.getFieldsValue()
            setLoading(true)
            const res = await comboApi.search({
                ...values,
                ...pagination,
                current_page: page - 1,
            })
            setCombos(res.responses)
            setTotal(res.totalRecord)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onExport = async () => {
        try {
            setLoading(true)
            const values = formSearch.getFieldsValue()
            const res = await comboApi.export(values)
            const a = document.createElement('a')
            a.href = URL.createObjectURL(res)
            a.download = 'danh_sach_ctkm.xlsx'
            a.click()
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onSubmitCU = async (values) => {
        console.log("🚀 ~ values:", values)
        try {
            setLoading(true)
            if (!selected) {
                await comboApi.createAndUpdate({
                    ...values,
                    combos: JSON.stringify([
                        {
                            productId: values.productId,
                            variantId: values.variantId,
                            quantity: values.quantity,
                            isMain: true
                        },
                        ...values.combos.map(combo => ({ ...combo, isMain: false }))
                    ]),
                })
                toast.success('Tạo CTKM thành công')
            } else {
                await comboApi.createAndUpdate({
                    id: selected.id,
                    ...values,
                    combos: JSON.stringify([
                        {
                            productId: values.productId,
                            variantId: values.variantId,
                            quantity: values.quantity,
                            isMain: true
                        },
                        ...values.combos.map(combo => ({ ...combo, isMain: false }))
                    ]),
                })
                toast.success('Sửa CTKM thành công')
            }
            closeModalCU()
            onSearch()
        } catch (error) {
            toast.error(error?.response?.data || (!selected ? 'Tạo CTKM thất bại' : 'Sửa CTKM thất bại'))
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        onSearch()
        dispatch(getListAllProduct())
    }, [])

    return (
        <>
            <div className={cx('header')}>
                <Typography.Title level={3} >Danh sách CTKM</Typography.Title>
                <Space>
                    {
                        (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
                        <Button size="large" icon={<PlusCircleFilled />} onClick={onOpenCreate}>
                            Thêm mới
                        </Button>
                    }
                    {/* <Button size="large" type='primary' icon={<FileExcelFilled />} onClick={onExport} >
                        Xuất Excel
                    </Button> */}
                </Space>
            </div>
            <Filter form={formSearch} onSearch={onChangePage} loading={loading} />
            <ComboTable
                openEdit={onOpenEdit}
                openDetail={onOpenDetail}
                loading={loading}
                pagination={pagination}
                total={total}
                onChangePage={onChangePage}
                products={combos} />
            <ModalCreateUpdate
                onFinish={onSubmitCU}
                form={formCombo}
                selected={selected}
                show={openCU}
                onCancel={closeModalCU} />
            <ModalDetail
                show={openDetail}
                selected={selected}
                onCancel={closeModalDetail}
            />
        </>
    )
};
