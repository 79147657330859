import { Layout } from "antd";
import { Outlet, Navigate } from "react-router-dom";
import { isUserLoggedIn } from "utils";

const { Content } = Layout
export default function PublicLayout() {
    if (isUserLoggedIn()) {
        return <Navigate to='/' />
    }
    return (
        <Layout >
            <Content>
                <Outlet />
            </Content>
        </Layout>
    )
}