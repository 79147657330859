import axiosService from "./axiosService"

const userApi = {
    changeUserStatus(id, params) {
        const url = `/user/change-user-status/${id}`
        return axiosService.get(url, { params })
    },
    getAll(params) {
        const url = '/user/list'
        return axiosService.get(url, { params })
    },
    getAllRole() {
        const url = '/user/roles'
        return axiosService.get(url)
    },
    changeInfo(body) {
        const url = '/user/account'
        return axiosService.post(url, body)
    },
    changePassword(body) {
        const url = '/user/change-password'
        return axiosService.post(url, body)
    },
    delete(id) {
        const url = `/user/${id}`
        return axiosService.delete(url)
    },
    create(body) {
        const url = '/auth/register'
        return axiosService.post(url, body)
    },
    changeRoleTeam(id, body) {
        const url = `/user/${id}/change-role-or-team`
        return axiosService.put(url, body)
    }
}

export default userApi;