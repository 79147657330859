import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { Card, Form, Input, Button, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import authApi from "api/auth";
import { useNavigate } from 'react-router-dom';
import { setToken, setUser } from "utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getListCategory, getListVariant } from "features/products/productSlice";
import { getListCountry } from "features/countries/countrySlice";
import { getListWarehouse } from "features/warehouses/warehouseSlice";
import { getListTopic } from "features/combos/comboSlice";
import { getListRole, getListTeam, updateInfo } from "features/users/userSlice";
import { getListCategoryAds } from "features/ads/adsSlice";
import logo from 'assets/images/logo.png'

const cx = classNames.bind(styles);

export default function LoginPage() {
    const dispatch = useDispatch()

    const navigate = useNavigate();
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const { token, userResponse } = await authApi.login(values);
            setUser(userResponse)
            setToken(token)
            dispatch(updateInfo(userResponse))
            toast.success('Đăng nhập thành công')
            navigate('/admin/statistic')
            dispatch(getListCategory())
            dispatch(getListCountry())
            dispatch(getListWarehouse())
            dispatch(getListVariant())
            dispatch(getListTopic())
            dispatch(getListRole())
            dispatch(getListTeam())
            dispatch(getListCategoryAds())
        } catch (error) {
            toast.error("Tài khoản hoặc mật khẩu không đúng")
        }
    };

    return (
        <div className={cx("page-login")}>
            <Card className={cx("login-card")} style={{ width: 500 }}>
                <div className={cx("logo")}>
                    <Image preview={false} src={logo} height={150} style={{ borderRadius: 75 }} />
                </div>
                <p className={cx("title")}>Đăng nhập</p>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder='Tên đăng nhập'
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Mật khẩu"
                            size="large"
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" className={cx("login-form-button")} size="large">
                        Đăng nhập
                    </Button>
                </Form>
            </Card>
        </div>
    );
}
