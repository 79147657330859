import { Button, Form, Input, Space } from "antd";
import userApi from "api/user";
import { toast } from "react-toastify";
import { getUser } from "utils/";

export function ChangePassword() {
    const [form] = Form.useForm();

    const checkPassword = (_, value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!value || (value.length >= 8 && regex.test(value))) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Mật khẩu chưa hợp lệ.'));
    }


    const onFinish = async (values) => {
        try {
            const userInfo = {
                ...values,
                username: getUser().username
            }
            await userApi.changePassword(userInfo)

            toast.success("Đổi mật khẩu thành công");
            form.resetFields();
        } catch (error) {
            toast.error('Mật khẩu cũ không đúng.');
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}>
            <Form.Item label="Mật khẩu cũ" name="oldPassword" rules={[
                { required: true }
            ]} >
                <Input.Password />
            </Form.Item>
            <Form.Item label="Mật khẩu mới" name="newPassword" rules={[
                { required: true },
                { validator: checkPassword, }
            ]} extra={<Space direction="vertical">
                <span>Mật khẩu ít nhất 8 ký tự</span>
                <span>Mật khẩu phải chứa ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt</span>
            </Space>}>
                <Input.Password />
            </Form.Item>
            <Form.Item label="Xác nhận mật khẩu" name="confirm" rules={[
                { required: true },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("Mật khẩu không khớp"));
                    },
                }),
            ]}>
                <Input.Password />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Cập nhật
                </Button>
            </Form.Item>
        </Form>
    )
}