import { CopyOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Form, Modal, Row, Space, Table } from 'antd';
import ladiPageApi from 'api/ladipage';
import { COMBO_STATUS } from 'constants/';
import { ROLE } from 'constants/';
import { ERROR_OCCUR } from 'constants/messages';
import { selectInfo } from 'features/users/userSlice';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import renderHtml from 'utils/renderHtml';

const renderColumns = (current, pageSize) => [
    {
        key: 'stt',
        dataIndex: 'stt',
        title: 'STT',
        render: (_, record, index) => (index + 1) + (current - 1) * pageSize
    },
    {
        key: 'createdUser',
        dataIndex: 'createdUser',
        title: 'Người tạo',
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Trạng thái',
        render: (_) => _ == 1 ? <Badge status="processing" text={COMBO_STATUS[0].label} /> : <Badge status="error" text={COMBO_STATUS[1].label} />
    },
    {
        key: 'mainProduct',
        dataIndex: 'mainProduct',
        title: 'Sản phẩm',
        render: (_) => _?.name
    },
    {
        key: 'country',
        dataIndex: 'country',
        title: 'Thị trường',
        render: (_) => _?.name
    },
    {
        key: 'comboSelectedDescription',
        dataIndex: 'comboSelectedDescription',
        title: 'Description',
        render: (_) => (
            _ &&
            <CopyToClipboard text={_.join('\n')}>
                <Space>
                    <CopyOutlined onClick={() => toast.success('Copy thành công')} />
                    <span dangerouslySetInnerHTML={{ __html: renderHtml(_.join('<br />')) }} />
                </Space>
            </CopyToClipboard>
        )
    },
    {
        key: 'categoryAds',
        dataIndex: 'categoryAds',
        title: 'Nền tảng QC',
        render: (_) => _?.name
    },
]

const ModalSelectCombo = ({ show, onCancel, user_id, onSubmit, selectedRows, setSelectedRows }) => {
    const userInfo = useSelector(selectInfo)

    const [loading, setLoading] = useState(false)
    const [apis, setApis] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 20,
    })

    const rowSelection = userInfo?.role?.id === ROLE.other ? '' : {
        selectedRowKeys: selectedRows.map(row => row.key),
        onChange: (selectedRowKeys, selectingRows) => {
            setSelectedRows([selectingRows[selectedRows?.length]])
        },
        preserveSelectedRowKeys: true,
    };

    const onChangePage = (page) => {
        setPagination({
            ...pagination,
            current_page: page
        })
        onSearch(page)
    }

    const onSearch = async (page = pagination.current_page) => {
        try {
            setLoading(true)
            const res = await ladiPageApi.search({
                user_id,
                ...pagination,
                current_page: page - 1,
            })
            setSelectedRows([])
            setApis(res.responses.map(item => ({
                ...item,
                key: item.ladingPageId,
            })))
            setTotal(res.totalRecord)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        show && onSearch()
    }, [show])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title='Chọn combo'
            footer=''
            width={1500}
        >
            <Row>
                <Col span={24}>
                    <Table
                        loading={loading}
                        columns={renderColumns(pagination.current_page, pagination.page_size)}
                        dataSource={apis}
                        rowSelection={rowSelection}
                        scroll={{ x: '2000px' }}
                        pagination={{ current: pagination.current_page, pageSize: pagination.page_size, total, onChange: onChangePage }} />
                </Col>
                <Space>
                    <Button disabled={selectedRows.length === 0} loading={loading} onClick={onSubmit} type='primary' size='large'>
                        Chọn
                    </Button>
                    <Button loading={loading} size='large' onClick={onCancel}>
                        Huỷ
                    </Button>
                </Space>

            </Row>
        </Modal >
    )
}

export default ModalSelectCombo;