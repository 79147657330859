import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Badge, Button, Image, Space, Tag } from "antd";
import { ROLE } from "constants/";
import { COMBO_STATUS } from "constants/";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { getUser } from "utils/";
import { numberWithCommas } from "utils/";
import renderHtml from "utils/renderHtml";

export const renderColumns = (current, pageSize, openEdit) => [
    {
        key: 'stt',
        dataIndex: 'stt',
        title: 'STT',
        render: (_, record, index) => (index + 1) + (current - 1) * pageSize
    },
    {
        key: 'createdUser',
        dataIndex: 'createdUser',
        title: 'Người tạo',
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Trạng thái',
        render: (_) => _ == 1 ? <Badge status="processing" text={COMBO_STATUS[0].label} /> : <Badge status="error" text={COMBO_STATUS[1].label} />
    },
    {
        key: 'url',
        dataIndex: 'url',
        title: 'API link',
        render: (_) => (
            <CopyToClipboard text={_}>
                <Space>
                    <CopyOutlined onClick={() => toast.success('Copy thành công')} />
                    {_}
                </Space>
            </CopyToClipboard>
        )
    },
    {
        key: 'comboSelectedDescription',
        dataIndex: 'comboSelectedDescription',
        title: 'Description',
        render: (_) => (
            _ &&
            <CopyToClipboard text={_.join('\n')}>
                <Space>
                    <CopyOutlined onClick={() => toast.success('Copy thành công')} />
                    <span dangerouslySetInnerHTML={{ __html: renderHtml(_.join('<br />')) }} />
                </Space>
            </CopyToClipboard>
        )
    },
    {
        key: 'mainProduct',
        dataIndex: 'mainProduct',
        title: 'Sản phẩm',
        render: (_) => _?.name
    },
    {
        key: 'country',
        dataIndex: 'country',
        title: 'Thị trường',
        render: (_) => _?.name
    },
    {
        key: 'categoryAds',
        dataIndex: 'categoryAds',
        title: 'Nền tảng QC',
        render: (_) => _?.name
    },
    {
        key: 'action',
        dataIndex: 'action',
        title: 'Hành động',
        render: (_, record) => (
            <Space>
                {getUser()?.role?.id != ROLE.other && <Button onClick={() => openEdit(record)} icon={<EditOutlined />} />}
            </Space>
        )
    }

]