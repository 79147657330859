import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import { DatePicker } from "components/";
import { COMBO_STATUS } from "constants/";
import { selectListTopic } from "features/combos/comboSlice";
import { selectListCountry } from "features/countries/countrySlice";
import { getListProductByWarehouse, selectListProductByWarehouse } from "features/products/productSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function Filter({ form, onSearch, loading }) {
    const dispatch = useDispatch()

    const listTopic = useSelector(selectListTopic)
    const listCountry = useSelector(selectListCountry)
    const listWarehouse = useSelector(selectListWarehouse)
    const listProductByWarehouse = useSelector(selectListProductByWarehouse)

    const countryId = Form.useWatch('country_id', form);
    const warehouseId = Form.useWatch('warehouse_id', form);

    const listWarehouseByCountry = useMemo(() => listWarehouse.filter(item => item.country.id === countryId), [countryId, listWarehouse])

    useEffect(() => {
        if (warehouseId) {
            dispatch(getListProductByWarehouse({ warehouse_id: warehouseId }))
        }
    }, [dispatch, warehouseId])

    useEffect(() => {
        form.setFieldsValue({
            warehouse_id: null,
            product_id: null
        })
    }, [countryId])

    useEffect(() => {
        form.setFieldsValue({
            product_id: null
        })
    }, [warehouseId])

    return (
        <Card>
            <Form form={form} onFinish={() => onSearch(1)} layout='vertical'>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label='Tên CTKM' name='name' >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Chủ đề' name='topic_id'>
                            <Select allowClear style={{ width: '100%' }} options={listTopic.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Trạng thái' name='status' >
                            <Select allowClear style={{ width: '100%' }}>
                                {
                                    COMBO_STATUS.map(item => (
                                        <Select.Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Thị trường' name='country_id'>
                            <Select allowClear style={{ width: '100%' }} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    {
                        countryId &&
                        <Col span={8}>
                            <Form.Item label='Kho' name='warehouse_id'>
                                <Select allowClear style={{ width: '100%' }} options={listWarehouseByCountry.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }
                    {
                        warehouseId &&
                        <Col span={8}>
                            <Form.Item label='Sản phẩm' name='product_id'>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    allowClear style={{ width: '100%' }} options={listProductByWarehouse.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
