import { Badge, Descriptions, Modal } from "antd";
import { COMBO_STATUS } from "constants/";
import { numberWithCommas } from "utils/";

export default function ModalDetail({ show, selected, onCancel }) {
    return (
        <Modal width={1000} title='Chi tiết CTKM' open={show} onCancel={onCancel} footer=''>
            <Descriptions bordered column={3}>
                <Descriptions.Item label='Tên CTKM'>
                    {selected?.name}
                </Descriptions.Item>
                <Descriptions.Item label='Mô tả' span={2}>
                    {selected?.description}
                </Descriptions.Item>
                <Descriptions.Item label='Trạng thái' span={3}>
                    {
                        selected?.status && (selected.status == 1 ? <Badge status="processing" text={COMBO_STATUS[0].label} /> : <Badge status="error" text={COMBO_STATUS[1].label} />)
                    }
                </Descriptions.Item>
                <Descriptions.Item label='Giá' span={3}>
                    {numberWithCommas(Number(selected?.price)?.toFixed(2) * selected?.countries.rate) + ' ' + selected?.countries.currency}
                </Descriptions.Item>
                <Descriptions.Item label='Thị trường'>
                    {selected?.countries.name}
                </Descriptions.Item>
                <Descriptions.Item label='Kho' span={2}>
                    {selected?.warehouse.name}
                </Descriptions.Item>
                {
                    selected?.comboDetailList.map(item => (
                        <>
                            <Descriptions.Item label={item.isMain === "true" ? 'Sản phẩm chính' : 'Sản phẩm'}>
                                {item.productName}
                            </Descriptions.Item>
                            <Descriptions.Item label='Thuộc tính'>
                                {item.variant.name}
                            </Descriptions.Item>
                            <Descriptions.Item label='Số lượng'>
                                {item.quantity}
                            </Descriptions.Item>
                        </>
                    ))
                }
            </Descriptions>
        </Modal>
    )
}