import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import topicApi from 'api/topic'


export const comboSlice = createSlice({
    name: 'combo',
    initialState: {
        listTopic: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListTopic.fulfilled, (state, action) => {
                state.listTopic = action.payload
            })
    },
})

export const getListTopic = createAsyncThunk(
    'combo/getListTopic',
    async (payload, { dispatch }) => {
        const res = await topicApi.getAll()
        return res
    }
)

export const selectListTopic = (state) => state.combo.listTopic

export default comboSlice.reducer
