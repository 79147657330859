import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Badge, Button, Image, Space, Tag } from "antd";
import { ROLE } from "constants/";
import { PRODUCT_STATUS } from "constants/";
import { getUser } from "utils/";

export const renderColumns = (current, pageSize, openEdit, openDetail) => [
    {
        key: 'stt',
        dataIndex: 'stt',
        title: 'STT',
        render: (_, record, index) => (index + 1) + (current - 1) * pageSize
    },
    {
        key: 'img',
        dataIndex: 'img',
        title: 'Ảnh',
        render: (_) => <Image width={60} src={process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_PATH + _} preview={false} />
    },
    {
        key: 'productCode',
        dataIndex: 'productCode',
        title: 'Mã SP',
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Tên SP',
    },
    {
        key: 'categoryProduct',
        dataIndex: 'categoryProduct',
        title: 'Danh mục',
        render: (_) => _.name
    },
    {
        key: 'country',
        dataIndex: 'country',
        title: 'Thị trường',
        render: (_) => _.name
    },
    {
        key: 'productStatus',
        dataIndex: 'productStatus',
        title: 'Trạng thái',
        render: (_) => _ == 1 ? <Badge status="processing" text={PRODUCT_STATUS[0].label} /> : <Badge status="error" text={PRODUCT_STATUS[1].label} />
    },
    {
        key: 'productUnit',
        dataIndex: 'productUnit',
        title: 'Đơn vị',
    },
    {
        key: 'createdUser',
        dataIndex: 'createdUser',
        title: 'Người tạo',
    },
    {
        key: 'createdAt',
        dataIndex: 'createdAt',
        title: 'Ngày tạo',
    },
    {
        key: 'endDate',
        dataIndex: 'endDate',
        title: 'Ngày dừng',
    },
    {
        key: 'action',
        dataIndex: 'action',
        title: 'Hành động',
        render: (_, record) => (
            <Space>
                <Button onClick={() => openDetail(record)} icon={<EyeOutlined />} />
                {
                    (getUser().role.id == ROLE.super_admin || getUser().role.id == ROLE.admin) &&
                    <Button onClick={() => openEdit(record)} icon={<EditOutlined />} />
                }
            </Space>
        )
    }

]