import { LockOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import userApi from "api/user";
import { ModalDelete } from "components/";
import { ERROR_OCCUR } from "constants/messages";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const { Option } = Select
export default function ModalResetPassword({ record, onSearch }) {
    const [form] = Form.useForm()

    const [show, setShow] = useState(false)
    const [showChangeStt, setShowChangeStt] = useState(false)
    const [loading, setLoading] = useState(false)

    const onCancel = () => {
        setShow(false)
        form.resetFields()
    }

    const checkPassword = (_, value) => {
        // check value has at least 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Character
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!value || (value.length >= 8 && regex.test(value))) {
            return Promise.resolve();
        }

        return Promise.reject(new Error('Mật khẩu chưa hợp lệ.'));
    }

    const onFinish = async (values) => {
        try {
            setLoading(true)
            await userApi.changePassword({
                ...values,
                username: record.username,
            })
            toast.success('Reset thành công!')
            setShow(false)
        } catch (error) {
            toast.error(error?.response?.data || 'Lỗi')
        } finally {
            setLoading(false)
        }
    }

    const onChangeStatus = async () => {
        try {
            await userApi.changeUserStatus(record.id, { status: record.status == 1 ? 0 : 1 })
            onCancel()
            onSearch()
            setShowChangeStt(false)
            toast.success('Đổi trạng thái thành công')
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    return (
        <>
            <Button icon={<LockOutlined />} onClick={() => setShow(true)} />
            <Modal
                open={show}
                onCancel={onCancel}
                title="Reset mật khẩu"
                footer=''
                width={800}
            >
                <Form form={form} onFinish={onFinish} layout='vertical'>
                    <Form.Item
                        label='Mật khẩu mới'
                        name='newPassword'
                        rules={[
                            { required: true },
                            { validator: checkPassword, }
                        ]}
                        extra={<Space direction="vertical">
                            <span>Mật khẩu ít nhất 8 ký tự</span>
                            <span>Mật khẩu phải chứa ít nhất 1 chữ hoa, 1 chữ thường, 1 số và 1 ký tự đặc biệt</span>
                        </Space>}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={8}>
                                <Space>
                                    <Button htmlType="submit" loading={loading} type='primary' size='large'>
                                        Cập nhật
                                    </Button>
                                    <Button size='large' loading={loading} onClick={onCancel}>
                                        Huỷ
                                    </Button>
                                </Space>
                            </Col>
                            <Col span={8} offset={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button loading={loading} size='large' onClick={() => setShowChangeStt(true)}>
                                    {record.status == 1 ? 'Nghỉ việc' : 'Cho làm lại'}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form >
            </Modal >
            <ModalDelete
                title='Đổi trạng thái'
                description="Bạn có chắc chắn không?"
                show={showChangeStt}
                onOk={onChangeStatus}
                okText='Ok'
                onCancel={() => setShowChangeStt(false)} />
        </>
    )
}