import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { InputCurrency } from "components/";
import { COMBO_STATUS } from "constants/";
import { selectListTopic } from "features/combos/comboSlice";
import { selectListCountry } from "features/countries/countrySlice";
import { getListProductByWarehouse, selectListProductByWarehouse } from "features/products/productSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComboForm from "./ComboForm";

const { Option } = Select
export default function ModalCreateUpdate({ form, onFinish, selected, show, onCancel }) {
    const dispatch = useDispatch()

    const listTopic = useSelector(selectListTopic)
    const listCountry = useSelector(selectListCountry)
    const listWarehouse = useSelector(selectListWarehouse)
    const listProductByWarehouse = useSelector(selectListProductByWarehouse)

    const countryId = Form.useWatch('countryId', form);
    const warehouseId = Form.useWatch('warehouseId', form);
    const productId = Form.useWatch('productId', form);
    const variantId = Form.useWatch('variantId', form);
    const combos = Form.useWatch('combos', form);

    const listWarehouseByCountry = useMemo(() => listWarehouse.filter(item => item.country.id === countryId), [countryId, listWarehouse])
    const listVariant = useMemo(() => productId ?
        listProductByWarehouse.find(item => item.id === productId)
            ?.productStockResponses[0]
            ?.productVariantResponses || [] : []
        , [listProductByWarehouse, productId])
    const quantityByVariant = useMemo(() => variantId && listVariant && listVariant.find(item => item.variant.id === variantId)?.quantity, [variantId, listVariant])

    const dynamicProductByWarehouse = useMemo(() => {
        const listDisabled = []
        if (productId) listDisabled.push(productId)
        combos?.map(item => item?.productId && listDisabled.push(item.productId))
        return listProductByWarehouse.map(item => ({
            ...item,
            disabled: listDisabled.includes(item.id)
        }))
    }, [combos, listProductByWarehouse, productId])

    const onChange = () => {
        form.setFieldsValue({
            productId: '',
            variantId: '',
            quantity: '',
            combos: []
        })
    }

    useEffect(() => {
        if (show && selected) {
            const { name, description, topic, status, warehouse, price, comboDetailList } = selected
            const mainProduct = comboDetailList.find(combo => combo.isMain === "true")
            form.setFieldsValue({
                name,
                description,
                topicId: topic.id,
                status: +status,
                price,
                countryId: warehouse.countryId,
                warehouseId: warehouse.id,
                productId: mainProduct.productId,
                variantId: mainProduct.variant.id,
                quantity: mainProduct.quantity,
                combos: comboDetailList.filter(combo => combo.isMain === "false").map(item => ({
                    productId: item.productId,
                    quantity: item.quantity,
                    variantId: item.variant.id
                }))
            })
        }
    }, [show, selected, form])

    useEffect(() => {
        if (warehouseId) {
            dispatch(getListProductByWarehouse({ warehouse_id: warehouseId }))
        }
    }, [dispatch, warehouseId])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title={!selected ? 'Thêm mới CTKM' : 'Cập nhật CTKM'}
            footer=''
            width={800}
        >
            <Form form={form} onFinish={onFinish} layout='vertical' >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label='Tên CTKM' name='name' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label='Mô tả' name='description' rules={[{ required: true }]} >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Chủ đề' name='topicId' rules={[{ required: true }]}>
                            <Select options={listTopic.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Trạng thái' name='status' rules={[{ required: true }]}>
                            <Select>
                                {COMBO_STATUS.map(item => (<Option key={item.value} value={item.value} >{item.label}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Giá' name='price' rules={[{ required: true }]}>
                            <InputCurrency />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Thị trường' name='countryId' rules={[{ required: true }]}>
                            <Select onChange={onChange} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Kho' name='warehouseId' rules={[{ required: true }]}>
                            <Select onChange={onChange} options={listWarehouseByCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Sản phẩm chính' name='productId' rules={[{ required: true }]}>
                            <Select options={dynamicProductByWarehouse.map(item => ({ value: item.id, label: item.name, disabled: item.disabled }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Thuộc tính' name='variantId' rules={[{ required: true }]}>
                            <Select options={listVariant?.map(item => ({ value: item.variant.id, label: item.variant.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Số lượng' name='quantity' rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} min={1} max={quantityByVariant} placeholder={quantityByVariant && `Còn trong kho: ${quantityByVariant}`} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <ComboForm combos={combos} listProduct={dynamicProductByWarehouse} listVariant={listVariant} />
                    </Col>
                    <Form.Item>
                        <Space>
                            <Button htmlType="submit" type='primary' size='large'>
                                {selected ? 'Cập nhật' : 'Tạo'}
                            </Button>
                            <Button size='large' onClick={onCancel}>
                                Huỷ
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form >
        </Modal >
    )
}