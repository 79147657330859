import { FileExcelFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Space, Table } from "antd";
import statisticApi from "api/statistic";
import userApi from "api/user";
import classNames from "classnames/bind";
import { DatePicker } from "components/";
import { ROLE } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { selectInfo, selectListTeam } from "features/users/userSlice";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { disabledPassDate } from "utils/";
import { disabledFutureDate } from "utils/";
import { formatVND } from "utils/";
import style from '../../index.module.scss'

const initialValues = { from: moment().startOf('M'), to: moment() }

const cx = classNames.bind(style);
export default function Top5ProductTable() {
    const [form] = Form.useForm()

    const teamId = Form.useWatch('team_id', form);
    const startDate = Form.useWatch('from', form);
    const endDate = Form.useWatch('to', form);

    const listTeam = useSelector(selectListTeam)
    const userInfo = useSelector(selectInfo)

    const [listUserByTeam, setListUserByTeam] = useState([])
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchUser = async () => {
        if (!teamId) {
            setListUserByTeam([])
            form.setFieldsValue({
                user_id: null
            })
        }
        try {
            const res = await userApi.getAll({
                team_id: teamId
            })
            setListUserByTeam(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    const columns = [
        {
            key: 'stt',
            dataIndex: 'stt',
            title: 'STT',
            render: (_, record, index) => index + 1
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Tên sản phẩm',
        },
        {
            key: 'category',
            dataIndex: 'category',
            title: 'Danh mục SP',
        },
        {
            key: 'country',
            dataIndex: 'country',
            title: 'Thị trường',
        },
        {
            key: 'totalOrders',
            dataIndex: 'totalOrders',
            title: 'Tổng đơn',
        },
        {
            key: 'totalRevenue',
            dataIndex: 'totalRevenue',
            title: 'Doanh số',
            render: _ => formatVND(_)
        },
    ]

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const res = await statisticApi.getTop5Product({
                ...values,
                from: moment(values.from).format('YYYY-MM-DD'),
                to: moment(values.to).format('YYYY-MM-DD'),
            })
            setProducts(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    const onExportProduct = async () => {
        try {
            const values = form.getFieldsValue()
            const res = await statisticApi.exportTop5Product({
                ...values,
                from: values.from.format('YYYY-MM-DD'),
                to: values.to.format('YYYY-MM-DD'),
            });
            const date = moment().format('YYYY/MM/DD_HH:mm:ss')
            const a = document.createElement('a')
            a.href = URL.createObjectURL(res)
            a.download = `DanhSachTheoSP_${date}.xlsx`
            a.click()
        } catch (error) {
            console.log("🚀 ~ error:", error)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [teamId])

    useEffect(() => {
        onSubmit(initialValues)
    }, [])

    return (
        <>
            <div className={cx('sub-header')}>
                <Button size="large" type='primary' icon={<FileExcelFilled />} onClick={onExportProduct} >
                    Xuất Excel
                </Button>
            </div>
            <Form form={form} onFinish={onSubmit} layout='vertical' initialValues={initialValues}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label='Ngày bắt đầu' name='from' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledFutureDate(current, endDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày kết thúc' name='to' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledPassDate(current, startDate)} />
                        </Form.Item>
                    </Col>
                    {
                        userInfo?.role?.id !== ROLE.other &&
                        <>
                            <Col span={6}>
                                <Form.Item label='Team' name='team_id'>
                                    <Select allowClear style={{ width: '100%' }} options={listTeam.map(item => ({ value: item.id, label: item.name }))} />
                                </Form.Item>
                            </Col>
                            {
                                teamId &&
                                <Col span={6}>
                                    <Form.Item label='Nhân viên' name='user_id'>
                                        <Select allowClear style={{ width: '100%' }} options={listUserByTeam.map(item => ({ value: item.id, label: item.username }))} />
                                    </Form.Item>
                                </Col>
                            }
                        </>
                    }
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                </Space>
            </Form>
            <Table
                loading={loading}
                columns={columns}
                dataSource={products}
                pagination={false} />
        </>
    )
}