import DOMPurify from 'dompurify';

// export function replaceNode() {}

export default function renderHtml(
  html,
  opts?
) {
  return DOMPurify.sanitize(html, opts);
}
