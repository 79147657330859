import axiosService from "./axiosService"

const productApi = {
    getByWarehouse(params) {
        const url = `/product/all`
        return axiosService.get(url, { params })
    },
    search(params) {
        const url = '/product/list'
        return axiosService.get(url, { params })
    },
    getDetail(id) {
        const url = `/product/${id}`
        return axiosService.get(url)
    },
    createAndUpdate(body) {
        const url = '/product/update'
        return axiosService.post(url, body)
    },
    export(params) {
        const url = '/product/export/excel'
        return axiosService.get(url, { params, responseType: 'blob' })
    }
}

export default productApi;