import axiosService from "./axiosService"

const categoryProductApi = {
    getAll() {
        const url = '/category/list'
        return axiosService.get(url)
    },
    delete(id) {
        const url = `/category/${id}`
        return axiosService.delete(url)
    },
    createAndUpdate(body) {
        const url = '/category/update'
        return axiosService.post(url, body)
    }
}

export default categoryProductApi;