import PublicLayout from './layouts/PublicLayout';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { getUser, isUserLoggedIn } from './utils';
import LoginPage from 'features/auth';
import AdminLayout from './layouts/AdminLayout';
import NotFound from './features/misc/NotFound';
import ProductPage from 'features/products';
import UserPage from 'features/users';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getListCategory, getListVariant } from 'features/products/productSlice';
import { getListCountry } from 'features/countries/countrySlice';
import { getListWarehouse } from 'features/warehouses/warehouseSlice';
import CountryPage from 'features/countries';
import VariantPage from 'features/products/pages/variant';
import CategoryProductPage from 'features/products/pages/category-product';
import WarehousePage from 'features/warehouses';
import TopicPage from 'features/combos/pages/topic';
import { getListTopic } from 'features/combos/comboSlice';
import AccountPage from 'features/users/pages/account';
import { getListRole, getListTeam, updateInfo } from 'features/users/userSlice';
import TeamPage from 'features/users/pages/team';
import CategoryAdsPage from 'features/ads/pages/category-ads';
import { getListCategoryAds } from 'features/ads/adsSlice';
import MyAdsPage from 'features/ads/pages/my-ads';
import AdsFeePage from 'features/ads';
import ComboPage from 'features/combos';
import LadiPage from 'features/ladipage';
import ListApiPage from 'features/ladipage/pages/apis';
import OrderPage from 'features/orders';
import StatisticPage from 'features/statistics';

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(updateInfo(getUser()))
    }
    dispatch(getListCategory())
    dispatch(getListCountry())
    dispatch(getListWarehouse())
    dispatch(getListVariant())
    dispatch(getListTopic())
    dispatch(getListRole())
    dispatch(getListTeam())
    dispatch(getListCategoryAds())
  }, [])

  const router = createBrowserRouter([
    {
      path: "/",
      element: !isUserLoggedIn() ? <Navigate to='/login' /> : <Navigate to='/admin/statistic' />,
    },
    {
      path: "/login",
      element: <PublicLayout />,
      children: [
        {
          index: true,
          element: <LoginPage />
        }
      ]
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        {
          index: true,
          path: "statistic",
          element: <StatisticPage />
        },
        {
          path: "products",
          element: <ProductPage />
        },
        {
          path: "users",
          element: <UserPage />
        },
        {
          path: "countries",
          element: <CountryPage />
        },
        {
          path: "variants",
          element: <VariantPage />
        },
        {
          path: "category-product",
          element: <CategoryProductPage />
        },
        {
          path: "warehouses",
          element: <WarehousePage />
        },
        {
          path: "topics",
          element: <TopicPage />
        },
        {
          path: "teams",
          element: <TeamPage />
        },
        {
          path: "category-ads",
          element: <CategoryAdsPage />
        },
        {
          path: "user-ads",
          element: <AdsFeePage />
        },
        {
          path: "my-ads",
          element: <MyAdsPage />
        },
        {
          path: "combos",
          element: <ComboPage />
        },
        {
          path: "apis",
          element: <ListApiPage />
        },
        {
          path: "ladipage",
          element: <LadiPage />
        },
        {
          path: "orders",
          element: <OrderPage />
        },
      ]
    },
    {
      path: '/profile',
      element: <AdminLayout />,
      children: [
        {
          index: true,
          element: <AccountPage />
        },
      ]
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default App
