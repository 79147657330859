import { Table } from "antd";
import { renderColumns } from "features/countries/constants";

export default function TableList({ list, loading, openEdit, openDelete }) {
    return (
        <Table
            loading={loading}
            columns={renderColumns(openEdit, openDelete)}
            dataSource={list}
            pagination={false} />
    )
};
