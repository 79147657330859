import { Table } from "antd";
import { renderColumns } from "features/products/constants";

export default function ProductTable({
    products,
    loading,
    openDetail,
    pagination: { current_page: current, page_size: pageSize },
    total,
    onChangePage,
    openEdit,
}) {
    return (
        <Table
            loading={loading}
            columns={renderColumns(current, pageSize, openEdit, openDetail)}
            dataSource={products}
            pagination={{ current, pageSize, total, onChange: onChangePage }} />
    )
}