import { CopyOutlined, UploadOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Table, Upload } from "antd";
import ladiPageApi from "api/ladipage";
import orderApi from "api/order";
import userApi from "api/user";
import { COMBO_STATUS } from "constants/";
import { ROLE } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { ApiTable } from "features/ladipage/pages/apis/components";
import { selectInfo } from "features/users/userSlice";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { normFile } from "utils/";
import ModalSelectCombo from "./ModalSelectCombo";
import renderHtml from "utils/renderHtml";

const renderColumns = (current, pageSize) => [
    {
        key: 'stt',
        dataIndex: 'stt',
        title: 'STT',
        render: (_, record, index) => (index + 1) + (current - 1) * pageSize
    },
    {
        key: 'createdUser',
        dataIndex: 'createdUser',
        title: 'Người tạo',
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Trạng thái',
        render: (_) => _ == 1 ? <Badge status="processing" text={COMBO_STATUS[0].label} /> : <Badge status="error" text={COMBO_STATUS[1].label} />
    },
    {
        key: 'url',
        dataIndex: 'url',
        title: 'API link',
        render: (_) => (
            <CopyToClipboard text={_}>
                <Space>
                    <CopyOutlined onClick={() => toast.success('Copy thành công')} />
                    {_}
                </Space>
            </CopyToClipboard>
        )
    },
    {
        key: 'comboSelectedDescription',
        dataIndex: 'comboSelectedDescription',
        title: 'Description',
        render: (_) => (
            _ &&
            <CopyToClipboard text={_.join('\n')}>
                <Space>
                    <CopyOutlined onClick={() => toast.success('Copy thành công')} />
                    <span dangerouslySetInnerHTML={{ __html: renderHtml(_.join('<br />')) }} />
                </Space>
            </CopyToClipboard>
        )
    },
    {
        key: 'mainProduct',
        dataIndex: 'mainProduct',
        title: 'Sản phẩm',
        render: (_) => _?.name
    },
    {
        key: 'country',
        dataIndex: 'country',
        title: 'Thị trường',
        render: (_) => _?.name
    },
    {
        key: 'categoryAds',
        dataIndex: 'categoryAds',
        title: 'Nền tảng QC',
        render: (_) => _?.name
    },
]

export default function ModalCreate({ form, show, onCancel, onSearchAll }) {
    const [listUser, setListUser] = useState([])
    const userInfo = useSelector(selectInfo)

    const [loading, setLoading] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [showSelectCombo, setShowSelectCombo] = useState(false);

    const userId = Form.useWatch('user_id', form);


    const fetchUser = async () => {
        try {
            if (userInfo?.role.id != ROLE.other) {
                const res = await userApi.getAll({
                    role_id: ROLE.other,
                })
                setListUser(res)
            } else {
                setListUser([{ id: userInfo.id, username: userInfo.username }])
                form.setFieldsValue({
                    user_id: userInfo.username
                })
            }
        } catch (error) {
            console.log("🚀 ~ error:", error)
        }
    }

    const onFinish = async (values) => {
        try {
            setLoading(true)
            delete values.user_id;
            await orderApi.create(selectedRows[0].url, values)
            onCancel()
            onSearchAll()
            toast.success('Tạo đơn hàng thành công')
        } catch (error) {
            console.log("🚀 ~ error:", error)
            toast.error(error?.response?.data || 'Tạo đơn hàng thất bại')
        } finally {
            setLoading(false)
        }
    }

    const onSubmit = () => {
        setShowSelectCombo(false)
    }

    const onCancelSelectCombo = () => {
        setSelectedRows([])
        setShowSelectCombo(false)
    }

    useEffect(() => {
        fetchUser()
    }, [])

    useEffect(() => {
        if (show) {
            form.resetFields()
            setSelectedRows([])
        }
    }, [show])

    return (
        <Modal
            open={show}
            onCancel={onCancel}
            title='Tạo đơn hàng'
            footer=''
            width={1500}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <Row gutter={[16]}>
                    <Col span={8}>
                        <Form.Item label='Người tạo' name='user_id' rules={[{ required: true }]}>
                            <Select allowClear style={{ width: '100%' }} options={listUser.map(item => ({ value: item.id, label: item.username }))} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Tên khách hàng' name='name' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='SĐT' name='phone' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Địa chỉ' name='address' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Note' name='note'>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>

                        <Form.Item label='Combo' name='combo' rules={[{ required: true }]}>
                            <Radio.Group>
                                {
                                    selectedRows.length > 0 &&
                                    <Space direction="vertical">
                                        {
                                            selectedRows[0].comboSelectedDescription.map(opt => (
                                                <Radio value={opt}>{opt}</Radio>
                                            ))
                                        }
                                    </Space>

                                }
                            </Radio.Group>
                        </Form.Item>
                        <Button style={{ marginBottom: '10px' }} disabled={!userId} onClick={() => setShowSelectCombo(true)}>
                            {selectedRows.length > 0 ? 'Chọn lại combo' : 'Chọn combo'}
                        </Button>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Số lượng' name='amount' rules={[{ required: true }]}>
                            <InputNumber min={1} />
                        </Form.Item>
                    </Col>
                    <ModalSelectCombo
                        show={showSelectCombo}
                        onSubmit={onSubmit}
                        onCancel={onCancelSelectCombo}
                        user_id={userId}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    />
                    <Col span={24}>
                        <Form.Item>
                            <Space>
                                <Button loading={loading} htmlType="submit" type='primary' size='large'>
                                    Tạo
                                </Button>
                                <Button loading={loading} size='large' onClick={onCancel}>
                                    Huỷ
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        </Modal >
    )
}