import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import categoryAdsApi from 'api/categoryAds'


export const adsSlice = createSlice({
    name: 'ads',
    initialState: {
        listCategory: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getListCategoryAds.fulfilled, (state, action) => {
                state.listCategory = action.payload
            })

    },
})

export const getListCategoryAds = createAsyncThunk(
    'ads/getListCategoryAds',
    async (payload, { dispatch }) => {
        const res = await categoryAdsApi.getAll()
        return res
    }
)

export const selectListCategoryAds = (state) => state.ads.listCategory

export default adsSlice.reducer
