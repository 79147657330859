import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import userApi from "api/user";
import { DatePicker } from "components/";
import { ORDER_STATUS } from "constants/";
import { ROLE } from "constants/";
import { EXPORT_STATUS } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { selectListCountry } from "features/countries/countrySlice";
import { selectInfo, selectListTeam } from "features/users/userSlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { disabledPassDate } from "utils/";
import { disabledFutureDate } from "utils/";

export default function Filter({ form, onSearch, loading }) {
    const listCountry = useSelector(selectListCountry)
    const listWarehouse = useSelector(selectListWarehouse)
    const listTeam = useSelector(selectListTeam)
    const userInfo = useSelector(selectInfo)

    const teamId = Form.useWatch('team_id', form);
    const countryId = Form.useWatch('country_id', form);
    const createdAt = Form.useWatch('createdAt', form);
    const updatedAt = Form.useWatch('updatedAt', form);

    const listWarehouseByCountry = useMemo(() => listWarehouse.filter(item => item.country.id === countryId), [countryId, listWarehouse])

    // const [listUser, setListUser] = useState([])

    const [listUserByTeam, setListUserByTeam] = useState([])

    const fetchUser = async () => {
        if (!teamId) {
            setListUserByTeam([])
        }
        form.setFieldsValue({
            order_processor: null
        })
        try {
            const res = await userApi.getAll({
                team_id: teamId,
                role_id: ROLE.other,
            })
            setListUserByTeam(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    // const fetchUser = async () => {
    //     try {
    //         if (userInfo?.role.id != ROLE.other) {
    //             const res = await userApi.getAll({
    //                 role_id: ROLE.other,
    //             })
    //             setListUser(res)
    //         } else {
    //             setListUser([{ id: userInfo.id, username: userInfo.username }])
    //             form.setFieldsValue({
    //                 order_processor: userInfo.username
    //             })
    //         }
    //     } catch (error) {
    //         console.log("🚀 ~ error:", error)
    //     }
    // }

    useEffect(() => {
        fetchUser()
    }, [teamId])

    useEffect(() => {
        form.setFieldsValue({
            warehouse_id: null
        })
    }, [countryId, form])

    useEffect(() => {
        if (userInfo?.role?.id == ROLE.other) {
            form.setFieldsValue({
                order_processor: userInfo?.username
            })
        }
    }, [userInfo])

    return (
        <Card>
            <Form
                form={form}
                onFinish={() => onSearch(1)}
                layout='vertical'
                initialValues={{
                    createdAt: [moment(), moment()],
                }}>
                <Row gutter={16}>
                    <Col span={6} style={{ display: userInfo?.role?.id == ROLE.other ? 'none' : '' }}>
                        <Form.Item label='Team' name='team_id'>
                            <Select allowClear style={{ width: '100%' }} options={listTeam.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='User' name='order_processor'>
                            <Select
                                // disabled={userInfo?.role?.id == ROLE.other}
                                allowClear style={{ width: '100%' }}
                                options={(userInfo?.role?.id == ROLE.other ? [userInfo] : listUserByTeam).map(item => ({ value: item.username, label: item.username }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày tạo' name='createdAt'>
                            <DatePicker.RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày cập nhật' name='updatedAt'>
                            <DatePicker.RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Trạng thái đơn hàng' name='status' >
                            <Select allowClear style={{ width: '100%' }}>
                                {
                                    ORDER_STATUS.map(item => (
                                        <Select.Option key={item.value} value={item.value}>
                                            {item.label}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Trạng thái xuất đơn' name='isExport'>
                            <Select options={EXPORT_STATUS} allowClear style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='SĐT' name='phone_number'>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Thị trường' name='country_id'>
                            <Select allowClear style={{ width: '100%' }} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    {
                        countryId &&
                        <Col span={12}>
                            <Form.Item label='Kho' name='warehouse_id'>
                                <Select allowClear style={{ width: '100%' }} options={listWarehouseByCountry.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
