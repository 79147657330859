import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Dropdown, Layout, Space } from "antd"
import classNames from "classnames/bind"
import { selectInfo } from "features/users/userSlice"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { removeUser } from "utils/"
import { getUser } from "utils/"
import { removeToken } from "utils/"
import style from './index.module.scss'

const { Header } = Layout
const cx = classNames.bind(style)

export default function Navbar() {
    const navigate = useNavigate()

    const userInfo = useSelector(selectInfo)

    const logout = () => {
        removeToken();
        removeUser();
        navigate("/login");
    };

    const items = [
        {
            key: "1",
            label: (
                <Link to='/profile'>Hồ sơ</Link>
            ),
        },
        {
            key: "2",
            label: (
                <div onClick={logout}>
                    <Space  >
                        <LogoutOutlined />
                        <span>Đăng xuất</span>
                    </Space>
                </div>
            ),
        },
    ];

    return (
        <Header className={cx('navbar')}>
            <Dropdown
                menu={{ items }}
                arrow
            >
                <Space>
                    {
                        userInfo?.img ? <Avatar size='large' src={process.env.REACT_APP_API_URL + process.env.REACT_APP_IMAGE_PATH + userInfo?.img} /> : <Avatar size='large' icon={<UserOutlined />} />
                    }
                    <strong>{userInfo?.name} - {userInfo?.role.name} {userInfo?.team?.name ? ' - TEAM' + userInfo?.team?.name : ''}</strong>
                </Space>
            </Dropdown>
        </Header>
    )
}