import { InputNumber } from "antd"
import { forwardRef } from "react"

const InputCurrency = forwardRef(function InputCurrency(props, ref) {
    return (
        <InputNumber
            ref={ref}
            // addonAfter="VND"
            formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            {...props} />
    )
})

export default InputCurrency