import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import userApi from "api/user";
import { DatePicker } from "components/";
import { ROLE } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { selectListCategoryAds } from "features/ads/adsSlice";
import { selectInfo, selectListTeam } from "features/users/userSlice";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "utils/";

export default function FormFilter({ listEmployee, form, onSearch, loading }) {
    const listCategoryAds = useSelector(selectListCategoryAds)

    const listTeam = useSelector(selectListTeam)
    const userInfo = useSelector(selectInfo)
    const teamId = Form.useWatch('team_id', form);
    const user_id = Form.useWatch('user_id', form)
    const category_ads_id = Form.useWatch('category_ads_id', form)
    const range = Form.useWatch('range', form)

    const [listUserByTeam, setListUserByTeam] = useState([])

    const fetchUser = async () => {
        if (!teamId) {
            setListUserByTeam([])
        }
        form.setFieldsValue({
            user_id: null
        })
        try {
            const res = await userApi.getAll({
                team_id: teamId,
                role_id: ROLE.other,
            })
            setListUserByTeam(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current >= moment().startOf('day');
    };

    useEffect(() => {
        if (category_ads_id && range?.[0] && range?.[1]) {
            onSearch(1)
        }
    }, [teamId, user_id, category_ads_id, range])

    // useEffect(() => {
    //     if (listEmployee.length > 0) {
    //         form.setFieldsValue({
    //             user_id: listEmployee[0].id
    //         })
    //     }
    // }, [listEmployee])

    useEffect(() => {
        if (listCategoryAds.length > 0) {
            form.setFieldsValue({
                category_ads_id: listCategoryAds[0].id
            })
        }
    }, [listCategoryAds])

    useEffect(() => {
        fetchUser()
    }, [teamId])

    useEffect(() => {
        if (userInfo?.role?.id == ROLE.other) {
            form.setFieldsValue({
                team_id: userInfo?.team.id
            })
        }
    }, [userInfo])

    return (
        <Card>
            <Form
                form={form}
                onFinish={() => onSearch(1)}
                layout='vertical'
                initialValues={{ range: [moment().startOf('M'), moment()] }}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label='Team' name='team_id'>
                            <Select
                                disabled={userInfo?.role?.id == ROLE.other}
                                allowClear
                                style={{ width: '100%' }}
                                options={listTeam.filter(item => {
                                    if (userInfo?.role?.id == ROLE.other) {
                                        return item.id == userInfo?.team.id
                                    }

                                    return item
                                }).map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='User' name='user_id'>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                options={
                                    (userInfo?.role?.id == ROLE.other ? [userInfo] : listUserByTeam).map(item => ({ value: item.id, label: item.username }))
                                }
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col span={6}>
                        <Form.Item label='Nhân viên' name='user_id' rules={[{ required: true }]}>
                            <Select
                                disabled={getUser().role.id == ROLE.other}
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%' }}
                                options={listEmployee.map(item => ({ value: item.id, label: item.username }))} />
                        </Form.Item>
                    </Col> */}
                    <Col span={6}>
                        <Form.Item label='Nền tảng QC' name='category_ads_id' rules={[{ required: true }]}>
                            <Select allowClear style={{ width: '100%' }} options={listCategoryAds.map(item => ({ value: item.id, label: item.name }))} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày bắt đầu - Ngày kết thúc' name='range' rules={[{ required: true }]}>
                            <DatePicker.RangePicker disabledDate={disabledDate} placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    {/* <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button> */}
                    <Button loading={loading} danger icon={<ClearOutlined />} onClick={() => form.resetFields()}>Xoá bộ lọc</Button>
                </Space>
            </Form>
        </Card >
    )
};
