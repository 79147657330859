import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Space, Table } from "antd";
import statisticApi from "api/statistic";
import { DatePicker } from "components/";
import { ROLE } from "constants/";
import { ERROR_OCCUR } from "constants/messages";
import { selectInfo, selectListTeam } from "features/users/userSlice";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { disabledPassDate } from "utils/";
import { disabledFutureDate } from "utils/";
import { formatVND } from "utils/";

const initialValues = { from: moment().startOf('M'), to: moment() }

export default function Top5TeamTable({
}) {
    const [formTeam] = Form.useForm()

    const startDate = Form.useWatch('from', formTeam);
    const endDate = Form.useWatch('to', formTeam);

    const [listTeam, setListTeam] = useState([])
    const [loading, setLoading] = useState(false)
    const listOptionTeam = useSelector(selectListTeam)
    const userInfo = useSelector(selectInfo)

    const columns = [
        {
            key: 'stt',
            dataIndex: 'stt',
            title: 'STT',
            render: (_, record, index) => index + 1
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Team',
        },
        {
            key: 'revenueWithTeam',
            dataIndex: 'revenueWithTeam',
            title: 'DS Ước Tính',
            render: _ => formatVND(_)
        },
        {
            key: 'totalFeeAds',
            dataIndex: 'totalFeeAds',
            title: 'Chi phí quảng cáo',
            render: _ => formatVND(_)
        },
        {
            key: 'ratioFeeAds',
            dataIndex: 'ratioFeeAds',
            title: '%CPQC Ước Tính',
        },
        {
            key: 'actualRevenueWithTeam',
            dataIndex: 'actualRevenueWithTeam',
            title: 'DS Thực Tế',
            render: _ => formatVND(_)
        },
        {
            key: 'actualRatioFeeAds',
            dataIndex: 'actualRatioFeeAds',
            title: '%CPQC Thực Tế',
        },
    ]

    const onSubmit = async (values) => {
        try {
            setLoading(true)
            const res = await statisticApi.getTopTeam({
                start_date: moment(values.from).format('YYYY-MM-DD'),
                end_date: moment(values.to).format('YYYY-MM-DD'),
                team_id: values.team_id,
            })
            setListTeam(res)
        } catch (error) {
            toast.error(error?.response?.data || ERROR_OCCUR)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        onSubmit(initialValues)
    }, [])

    return (
        <>
            <Form form={formTeam} onFinish={onSubmit} layout='vertical' initialValues={initialValues}>
                <Row gutter={16}>
                    {
                        userInfo?.role?.id != ROLE.other &&
                        <Col span={6}>
                            <Form.Item label='Team' name='team_id'>
                                <Select allowClear style={{ width: '100%' }} options={listOptionTeam.map(item => ({ value: item.id, label: item.name }))} />
                            </Form.Item>
                        </Col>
                    }
                    <Col span={6}>
                        <Form.Item label='Ngày bắt đầu' name='from' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledFutureDate(current, endDate)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label='Ngày kết thúc' name='to' rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={(current) => disabledPassDate(current, startDate)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Space>
                    <Button loading={loading} type="primary" htmlType='submit' icon={<SearchOutlined />}>Tìm kiếm</Button>
                </Space>
            </Form>
            <Table
                loading={loading}
                columns={columns}
                dataSource={listTeam}
                pagination={false} />
        </>
    )
}