import { Button, Col, Form, Row, Select } from "antd";
import { selectListCategoryAds } from "features/ads/adsSlice";
import { getListProductByWarehouse, selectListProductByWarehouse } from "features/products/productSlice";
import { selectListCountry } from "features/warehouses/countrySlice";
import { selectListWarehouse } from "features/warehouses/warehouseSlice";
import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepContext } from "../Context";

export default function Step1({ onFinish }) {
    const { curStep, formStep1, formStep2 } = useContext(StepContext)
    const dispatch = useDispatch()

    const countryId = Form.useWatch('countryId', formStep1)
    const warehouseId = Form.useWatch('warehouseId', formStep1)
    const productId = Form.useWatch('productId', formStep1)

    const listCountry = useSelector(selectListCountry)
    const listWarehouse = useSelector(selectListWarehouse)
    const listProductByWarehouse = useSelector(selectListProductByWarehouse)
    const listCategoryAds = useSelector(selectListCategoryAds)

    const listWarehouseByCountry = useMemo(() => listWarehouse.filter(item => item.country.id === countryId), [countryId, listWarehouse])
    // const listVariant = useMemo(() => productId ?
    //     listProductByWarehouse.find(item => item.id === productId)
    //         ?.productStockResponses[0]
    //         ?.productVariantResponses || [] : []
    //     , [listProductByWarehouse, productId])

    const onChangeCountry = () => {
        formStep1.setFieldsValue({
            warehouseId: null,
            productId: null,
            variantId: null,
        })
    }

    const onChangeWarehouse = () => {
        formStep1.setFieldsValue({
            productId: null,
            variantId: null,
        })
    }

    const onSubmit = (values) => {
        onFinish({
            countryName: listCountry.find(item => item.id === values.countryId).name,
            warehouseName: listWarehouseByCountry.find(item => item.id === values.warehouseId).name,
            productName: listProductByWarehouse.find(item => item.id === values.productId).name,
            // variantName: listVariant.find(item => item.variant.id === values.variantId).variant.name,
            categoryAdsName: listCategoryAds.find(item => item.id === values.categoryAdsId).name,
        })
    }

    useEffect(() => {
        if (warehouseId) {
            dispatch(getListProductByWarehouse({ warehouse_id: warehouseId }))
        }
    }, [dispatch, warehouseId])

    return (
        <Row gutter={12}>
            <Col span={12}>
                <Form form={formStep1} layout='vertical' onFinish={onSubmit}>
                    <Form.Item label='Chọn thị trường' name='countryId' rules={[{ required: true }]}>
                        <Select onChange={onChangeCountry} options={listCountry.map(item => ({ value: item.id, label: item.name }))} />
                    </Form.Item>
                    <Form.Item label='Chọn kho' name='warehouseId' rules={[{ required: true }]}>
                        <Select onChange={onChangeWarehouse} options={listWarehouseByCountry.map(item => ({ value: item.id, label: item.name }))} />
                    </Form.Item>
                    <Form.Item label='Chọn sản phẩm chính' name='productId' rules={[{ required: true }]}>
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            } options={listProductByWarehouse.map(item => ({ value: item.id, label: item.name }))} />
                    </Form.Item>
                    {/* <Form.Item label='Chọn thuộc tính' name='variantId' rules={[{ required: true }]}>
                        <Select options={listVariant.map(item => ({ value: item.variant.id, label: item.variant.name }))} />
                    </Form.Item> */}
                    <Form.Item label='Nền tảng quảng cáo' name='categoryAdsId' rules={[{ required: true }]}>
                        <Select options={listCategoryAds.map(item => ({ value: item.id, label: item.name }))} />
                    </Form.Item>
                    <Form.Item >
                        <Button type='primary' htmlType='submit'>
                            Tiếp tục
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            {/* <Col span={12}>
                <strong>Danh sách API có sẵn</strong>
            </Col> */}
        </Row>
    )
}